<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span>
        Editar Obra
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editProject">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <!-- name -->
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre*'" label-for="project-name">
                  <b-form-input
                    v-model="project.name"
                    name="name"
                    :placeholder="'Nombre*'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- ref -->
              <validation-provider
                #default="{ errors }"
                :name="'ref'"
                rules="required"
              >
                <b-form-group :label="'Referencia*'" label-for="ref">
                  <b-form-input
                    v-model="project.ref"
                    name="ref"
                    :placeholder="'Referencia*'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                :name="'Cliente'"
                rules="required"
              >
                <b-form-group :label="'Cliente*'" label-for="project-client">
                  <v-select
                    v-model="project.client"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectClients"
                    :placeholder="'Cliente*'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-form-group
                :label="'Descripción'"
                label-for="project-description"
              >
                <quill-editor v-model="project.description" />
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group :label="$t('Pais')" label-for="register-country-client">
                <validation-provider #default="{ errors }" :name="$t('Pais')" rules="required">
                  <v-select
                      id="register-country-client"
                      @input="handlerCountry"
                      :value="countrySelected"
                      :state="errors.length > 0 ? false : null"
                      :options="listCountries"
                      label="name"
                      :clearable="false"
                      name="register-country-client"
                      :placeholder="$t('Pais')"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- province -->
              <b-form-group
                  :label="$t('Provincia')"
                  label-for="register-province-client"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Provincia')"
                    rules="required"
                >
                  <v-select
                      v-if="project.country && project.country.code === 'ES'"
                      v-model="project.province_object"
                      label="name"
                      :options="selectProvinces"
                      :filterable="true"
                      :searchable="true"
                      :placeholder="$t('Provincia')"
                  />
                  <b-form-input
                      v-else
                      v-model="project.province"
                      :placeholder="$t('Provincia')"
                      :disabled="!project.country"
                  />
                  <small
                      ref="errorField"
                      class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>              <!-- address -->
              <validation-provider
                #default="{ errors }"
                :name="'Dirección'"
                rules="required"
              >
                <b-form-group :label="'Dirección*'" label-for="project-address">
                  <b-form-input
                    v-model="project.address"
                    name="address"
                    :placeholder="'Dirección*'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- responsable id -->
              <validation-provider
                #default="{ errors }"
                :name="'Responsable'"
                rules="required"
              >
                <b-form-group
                  :label="'Responsable de mantenimiento*'"
                  label-for="project-user"
                >
                  <v-select
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectUsers"
                    :placeholder="'Responsable de mantenimiento*'"
                    v-model="project.responsible"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- docs -->
              <b-form-group :label="'Documentos'" label-for="project-documents">
                <b-link
                  :to="{ name: 'viewProject', params: { id: project.id, tabIndex: 2 } }"
                  class="d-inline-block btn-primary"
                >
                  <span>{{ "Adjuntar documentos" }}</span>
                </b-link>
              </b-form-group>
            </b-col>

            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ "Guardar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
  BInputGroup,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { config } from "@/shared/app.config";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    vSelect,
    BFormCheckbox,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      statusProjectsText: config.statusProjectsText,
      required,
      projectPrepare: false,
      isDisabled: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters({
      project: "projects/getProject",
      countries: 'countries/getCountries',
      selectUsers: "users/getSelectUsers",
      selectClients: "clients/getSelectClients",
      selectProvinces: "provinces/getSelectProvinces",
    }),
    listCountries () {
      return this.countries.map((countrie) => {
        countrie.name = countrie.title['es']
        return countrie
      })
    },
    countrySelected() {
      return { ...this.project.country, name: this.project?.country?.title['es'] }
    },
  },
  methods: {
    ...mapActions({
      edit: "projects/edit",
      getProject: "projects/getProject",
      searchUsers: "users/selectUsers",
      searchClients: "clients/selectClients",
      getSelectProvinces: "provinces/selectProvinces",
      getListCountries: 'countries/getListCountries',
    }),
    handlerCountry(value) {
      this.project.country = value
      this.project.province = ''
    },
    removeItem(index) {
      this.items.splice(index, 1);
      if (this.items.length < 3) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
      if (this.$refs.row[0].offsetHeight) {
        this.trTrimHeight(this.$refs.row[0].offsetHeight);
      }
    },
    handleSubmit() {
      this.$refs.editProject.validate().then((success) => {
        if (success) {
          const { id } = this.project;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, project: formData });
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end',
          });
        }
      });
    },
    setData() {
      this.projectPrepare = true;
    },
    createFormData() {
      const data = new FormData();

      data.append("name", this.project.name);
      data.append("ref", this.project.ref);
      data.append("client_id", this.project.client.id);
      data.append("description", this.project.description);
      data.append("responsible_id", this.project.responsible.id);
      data.append("address", this.project.address);
      data.append('province', this.project.country.code === 'ES' ? this.project.province_object.id : this.project.province)

      data.append('country_id', this.project.country.id)


      return data;
    },
  },
  async created() {
    await this.getProject(this.$route.params.id);
    await this.searchUsers({
      page: 1,
      per_page: 999,
      search: "",
      roles: ["tec"],
    });
    await this.searchClients();

    await this.setData();

    await this.getListCountries({
      page: 1, per_page: 9999, search: '', orderBy: '',
    })
    await this.getSelectProvinces({
      page: 1,
      per_page: 9999,
      search: "",
      orderBy: "",
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.icon-trash {
  padding-top: 7px;
}
</style>
