var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0 hGoBack"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" Editar Obra ")])]),_c('b-card',[_c('validation-observer',{ref:"editProject"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre*',"label-for":"project-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre*'},model:{value:(_vm.project.name),callback:function ($$v) {_vm.$set(_vm.project, "name", $$v)},expression:"project.name"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'ref',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Referencia*',"label-for":"ref"}},[_c('b-form-input',{attrs:{"name":"ref","placeholder":'Referencia*'},model:{value:(_vm.project.ref),callback:function ($$v) {_vm.$set(_vm.project, "ref", $$v)},expression:"project.ref"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Cliente',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Cliente*',"label-for":"project-client"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectClients,"placeholder":'Cliente*'},model:{value:(_vm.project.client),callback:function ($$v) {_vm.$set(_vm.project, "client", $$v)},expression:"project.client"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":'Descripción',"label-for":"project-description"}},[_c('quill-editor',{model:{value:(_vm.project.description),callback:function ($$v) {_vm.$set(_vm.project, "description", $$v)},expression:"project.description"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Pais'),"label-for":"register-country-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pais'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"register-country-client","value":_vm.countrySelected,"state":errors.length > 0 ? false : null,"options":_vm.listCountries,"label":"name","clearable":false,"name":"register-country-client","placeholder":_vm.$t('Pais')},on:{"input":_vm.handlerCountry}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Provincia'),"label-for":"register-province-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Provincia'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.project.country && _vm.project.country.code === 'ES')?_c('v-select',{attrs:{"label":"name","options":_vm.selectProvinces,"filterable":true,"searchable":true,"placeholder":_vm.$t('Provincia')},model:{value:(_vm.project.province_object),callback:function ($$v) {_vm.$set(_vm.project, "province_object", $$v)},expression:"project.province_object"}}):_c('b-form-input',{attrs:{"placeholder":_vm.$t('Provincia'),"disabled":!_vm.project.country},model:{value:(_vm.project.province),callback:function ($$v) {_vm.$set(_vm.project, "province", $$v)},expression:"project.province"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v(" "),_c('validation-provider',{attrs:{"name":'Dirección',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Dirección*',"label-for":"project-address"}},[_c('b-form-input',{attrs:{"name":"address","placeholder":'Dirección*'},model:{value:(_vm.project.address),callback:function ($$v) {_vm.$set(_vm.project, "address", $$v)},expression:"project.address"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":'Responsable',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Responsable de mantenimiento*',"label-for":"project-user"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectUsers,"placeholder":'Responsable de mantenimiento*'},model:{value:(_vm.project.responsible),callback:function ($$v) {_vm.$set(_vm.project, "responsible", $$v)},expression:"project.responsible"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"project-documents"}},[_c('b-link',{staticClass:"d-inline-block btn-primary",attrs:{"to":{ name: 'viewProject', params: { id: _vm.project.id, tabIndex: 2 } }}},[_c('span',[_vm._v(_vm._s("Adjuntar documentos"))])])],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Guardar")+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }